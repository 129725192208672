.world--container{
  align-items:center;
  justify-items: center;
  padding: 0;
  margin: 0;
  width: 100%;
  height: 100%;
  grid-area: buildingArea;
  background-image: $skyline;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: #b49d77;
  display: grid;
  grid-template-areas:
  "levelMarkerArea"
  "rowContainerArea"
  "carContainerArea";
  grid-template-rows: 15% 70% 15%;
  grid-template-columns: 100%;
  z-index: 0;
}

.world--container-dark{
  box-sizing: border-box;
  position: absolute;
  padding: 0;
  margin: 0;
  width: 100%;
  height: 100%;
  background-color: #00000090;
  background-size: cover;
  z-index: 8;
}

.world--level-marker{
  align-self: center;
  grid-area: levelMarkerArea;
  display: grid;
  z-index: 10;
}

.world--level-marker-text{
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  top: 11%;
  font-size: calc(1.15vh + 1.15vw);
  text-shadow: 0 0.1em 0.4em #1a2946;
}

.world--time-text{
  line-height: 1.25;
  padding: 0.5em;
  background-color: #00000030;
  box-shadow: 0 0.1em 0.4em #00000040;
  font-size: calc(1vh + 1vw);
  text-shadow: 0 0 0.5em #000000AF;
  color: #f8c8c8;
  position: absolute;
  top: 11%;
  right: 2%;
  border-radius: 0.2em;
}

.world--score-text{
  line-height: 1.25;
  padding: 0.5em;
  background-color: #00000030;
  box-shadow: 0 0.1em 0.4em #00000040;
  font-size: calc(1vh + 1vw);
  text-shadow: 0 0 0.5em #000000AF;
  color: rgb(223, 255, 204);
  position: absolute;
  top: 11%;
  left: 2%;
  border-radius: 0.2em;
}

.world--vehicle-container{
  align-items: center;
  height: 100%;
  -webkit-width: 100%;
  width: 100vw;
  z-index: 2;
}

.world--vehicle{
  height: 100%;
  width: auto;
  background-color: transparent;
  z-index: 3;
}

.world--vehicle--drive-left-1{
  animation: driveLeft1 25s ease infinite;
  animation-fill-mode: backwards;
}

.world--vehicle--drive-left-2{
  animation: driveLeft2 25s ease 10s infinite;
  animation-fill-mode: backwards;
}

.world--vehicle--drive-right-1{
  animation: driveRight1 25s ease-in-out 5s infinite;
  animation-fill-mode: backwards;
}

.world--vehicle--drive-right-2{
  animation: driveRight2 25s ease-in-out 15s infinite;
  animation-fill-mode: backwards;
}

.world--vehicle--drive-right-3{
  animation: driveRight2 25s ease-in-out 25s infinite;
  animation-fill-mode: backwards;
}

@keyframes driveLeft1{
  from {transform: translateX(80vw);}
  to {transform: translateX(-120vw);}
}

@keyframes driveLeft2{
  from {transform: translateX(80vw);}
  to {transform: translateX(-175vw);}
}

@keyframes driveRight1{
  from {transform: translateX(-80vw);}
  to {transform: translateX(120vw);}
}

@keyframes driveRight2{
  from {transform: translateX(-80vw);}
  to {transform: translateX(175vw);}
}

@keyframes driveRight3{
  from {transform: translateX(-80vw);}
  to {transform: translateX(200vw);}
}

@media (orientation: landscape){

  .world--container{
    grid-template-rows: 35% 50% 15%;
  }

  .world--container-dark{
    -webkit-max-width: 50%;
    max-width: 50vw;
  }

  .world--vehicle-container{
    -webkit-max-width: 50%;
    max-width: 50vw;
  }

  .world--level-marker-text{
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 50%;
  }

  .world--time-text{
    right: 52%;
  }

  .world--score-text{
    left: 2%;
  }
}

@media (max-width: 1200px){
  
}


.effect--glow{
    animation: glow 2s linear infinite;
  }

.effect--pump{
    animation: pump 1s alternate infinite;
}
  
  @keyframes glow{
    0% {opacity: 1; filter: brightness(1);}
    50% {opacity: 0.15; filter: brightness(0.75);}
    100% {opacity: 1; filter: brightness(1);}
  }

  @keyframes pump{
    from {opacity: 0.6; transform: scale(1)}
    to {opacity: 1; transform: scale(1.02)}
  }

  @keyframes pump-big{
    from {transform: scale(1)}
    to {transform: scale(1.1); box-shadow: 0 0.2em 0.6em #00000080}
  }
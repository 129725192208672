@import "variables";
@import "world";
@import "building";
@import "menu";
@import "effects";

.app--page {
  height:100%;
  width: 100%;
  background-color: $bg-color;
  display: grid;
  justify-items: center;
  align-items: center;
  align-self: center;
  grid-template-areas:
  "headerArea"
  "contentArea";
  grid-template-rows: 10% 90%;
}

.app--header__logo {
  display: grid;
  max-height: 100%;
  width: auto;
  grid-area: headerLogoArea;
}

.app--header__nav{
  max-height: 100%;
  display: grid;
  margin-right: 0.5em;
  justify-self: end;
  grid-area: headerNavArea;
}

.app--header__nav button{
  border-radius: 0;
  animation: none;
  border: none;
  font-size: calc(0.65vh + 0.65vw);
  background-color: #848484;
  box-shadow: 0 0.1em 0.3em #00000040;
  text-shadow: 0 0.1em 0.2em #00000080;
  margin: 0.25em;
  padding: 0.5em;
  color: white;
  text-decoration: none;
}

.app--header__nav button:hover{
  background-color: #919191;
  box-shadow: 0 0.2em 0.5em #00000040;
  color: rgb(146, 197, 255);
}

.app--header__nav button:active{
  box-shadow: 0 0 0.1em #00000070;
  background-color: #535353;
  color: rgb(255, 107, 107);
}

.app--header__high-score {
  font-size: calc(0.85vw + 0.85vh);
  max-height: 100%;
  justify-self: start;
  margin-left: 2em;
  text-shadow: 0 0 0.5em #00000080;
  line-height: 1.25;
  display: grid;
  grid-area: highScoreArea;
}

.app--header {
  display: grid;
  justify-items: center;
  align-items: center;
  width: 100%;
  height: 100%;
  grid-area: headerArea;
  background-image: linear-gradient(-45deg, #848484, #535353);
  box-shadow: 0 0.2em 0.4em #00000040;
  z-index: 5;
  grid-template-areas: 
  "highScoreArea headerLogoArea headerNavArea";
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 100%;
}

.app--content {
  background-color: $bg-color;
  align-items: center;
  justify-items: center;
  width: 100%;
  height: 100%;
  grid-area: contentArea;
  display: grid;
  grid-template-areas:
  "buildingArea"
  "menuArea";
  grid-template-rows: 45% 55%;
}


@media only screen and (orientation: landscape) {
  .app--content{
    grid-template-areas:
    "buildingArea menuArea";
    grid-template-columns:50% 50%;
    grid-template-rows: 100%;
  }

  .app--header__nav{
    display: flex;
  }

}


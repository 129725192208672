@import 'variables';

.building--row-container{
  height: 100%;
  width: 100%;
  display: grid;
  grid-area: rowContainerArea;
  grid-template-areas:
  "sourceRowArea"
  "userRowArea";
  grid-template-rows: 50% 50%;
}

.building--row{
  height: 100%;
  width: 100%;
  display: grid;
  align-self: center;
  align-items: center;
  justify-items: center;
  grid-template-rows: 100%;
}

.building--row__source{
  grid-area: sourceRowArea;
  grid-template-areas:
  ". buildingLotArea1 buildingLotArea2 buildingLotArea3 .";
  grid-template-columns: 15% 25% 25% 25% 10%;
  z-index: 1;
}
.building--row__user{
  grid-area: userRowArea;
  grid-template-areas:
  ". buildingLotArea1 buildingLotArea2 buildingLotArea3 .";
  grid-template-columns: 10% 25% 25% 25% 15%;
  z-index: 2;
}

BldgPart {
  align-items: end;
  display: grid;
  width: 100%;
  height: 100%;
  background-size: contain;
  background-repeat: no-repeat;
}


//Lot Areas
.lot--1{
  grid-area: buildingLotArea1;
}

.lot--2{
  grid-area: buildingLotArea2;
}

.lot--3{
  grid-area: buildingLotArea3;
}

//Placeholder Images (not currently used)
.placeholder--example-1{
  background-image: $source-lot--1;
}

.placeholder--example-2{
  background-image: $source-lot--2;
}

.placeholder--example-3{
  background-image: $source-lot--3;
}

.placeholder--user-1{
  background-image: $user-lot--1;
}

.placeholder--user-2{
  background-image: $user-lot--2;
}

.placeholder--user-3{
  background-image: $user-lot--3;
}

//Grounds
.ground--vacant{
  background-image: $blank--zone;
}

.ground--grass{
  background-image: $house--zone;
}

.ground--asphalt{
  background-image: $office--zone;
}


//Foundations
.foundation--brick{
  background-image: $base__brick;
}

.foundation--concrete{
  background-image: $base__gray;
}

.foundation--marble{
  background-image: $base__marble;
}

.foundation--wood{
  background-image: $base__wood;
}

//Stairs
.stairs--brick{
  background-image: $stairs__brick;
}

.stairs--concrete{
  background-image: $stairs__gray;
}

.stairs--marble{
  background-image: $stairs__marble;
}

.stairs--wood{
  background-image: $stairs__wood;
}

//Mains

.main--house-red{
  background-image: $house--main__red;
}

.main--house-green{
  background-image: $house--main__green;
}

.main--house-blue{
  background-image: $house--main__blue;
}

.main--office-red{
  background-image: $office--main__red;
}

.main--office-green{
  background-image: $office--main__green;
}

.main--office-blue{
  background-image: $office--main__blue;
}

.main--office-glass{
  background-image: $empty--main;
}


//Roofs

.roof--house-red{
  background-image: $house--roof__red;
}

.roof--house-green{
  background-image: $house--roof__green;
}

.roof--house-blue{
  background-image: $house--roof__blue;
}

.roof--office-red{
  background-image: $office--roof__red;
}

.roof--office-green{
  background-image: $office--roof__green;
}

.roof--office-blue{
  background-image: $office--roof__blue;
}

.roof--office-glass{
  background-image: $empty--roof;
}


//Decor
.decor--flowers{
  background-image: $accessory--flowers;
}

.decor--graffiti{
  background-image: $accessory--graffiti;
}

.decor--cactii{
  background-image: $accessory--cactii;
}

.decor--logo{
  background-image: $accessory--logo;
}

.decor--damage{
  background-image: $accessory--hole;
}

.decor--trees{
  background-image: $accessory--trees;
}

.decor--vines{
  background-image: $accessory--vines;
}





.world--container {
  align-items: center;
  justify-items: center;
  padding: 0;
  margin: 0;
  width: 100%;
  height: 100%;
  grid-area: buildingArea;
  background-image: url(../images/skyline.svg);
  background-repeat: no-repeat;
  background-size: cover;
  background-color: #b49d77;
  display: grid;
  grid-template-areas: "levelMarkerArea" "rowContainerArea" "carContainerArea";
  grid-template-rows: 15% 70% 15%;
  grid-template-columns: 100%;
  z-index: 0; }

.world--container-dark {
  box-sizing: border-box;
  position: absolute;
  padding: 0;
  margin: 0;
  width: 100%;
  height: 100%;
  background-color: #00000090;
  background-size: cover;
  z-index: 8; }

.world--level-marker {
  align-self: center;
  grid-area: levelMarkerArea;
  display: grid;
  z-index: 10; }

.world--level-marker-text {
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  top: 11%;
  font-size: calc(1.15vh + 1.15vw);
  text-shadow: 0 0.1em 0.4em #1a2946; }

.world--time-text {
  line-height: 1.25;
  padding: 0.5em;
  background-color: #00000030;
  box-shadow: 0 0.1em 0.4em #00000040;
  font-size: calc(1vh + 1vw);
  text-shadow: 0 0 0.5em #000000AF;
  color: #f8c8c8;
  position: absolute;
  top: 11%;
  right: 2%;
  border-radius: 0.2em; }

.world--score-text {
  line-height: 1.25;
  padding: 0.5em;
  background-color: #00000030;
  box-shadow: 0 0.1em 0.4em #00000040;
  font-size: calc(1vh + 1vw);
  text-shadow: 0 0 0.5em #000000AF;
  color: #dfffcc;
  position: absolute;
  top: 11%;
  left: 2%;
  border-radius: 0.2em; }

.world--vehicle-container {
  align-items: center;
  height: 100%;
  -webkit-width: 100%;
  width: 100vw;
  z-index: 2; }

.world--vehicle {
  height: 100%;
  width: auto;
  background-color: transparent;
  z-index: 3; }

.world--vehicle--drive-left-1 {
  -webkit-animation: driveLeft1 25s ease infinite;
          animation: driveLeft1 25s ease infinite;
  -webkit-animation-fill-mode: backwards;
          animation-fill-mode: backwards; }

.world--vehicle--drive-left-2 {
  -webkit-animation: driveLeft2 25s ease 10s infinite;
          animation: driveLeft2 25s ease 10s infinite;
  -webkit-animation-fill-mode: backwards;
          animation-fill-mode: backwards; }

.world--vehicle--drive-right-1 {
  -webkit-animation: driveRight1 25s ease-in-out 5s infinite;
          animation: driveRight1 25s ease-in-out 5s infinite;
  -webkit-animation-fill-mode: backwards;
          animation-fill-mode: backwards; }

.world--vehicle--drive-right-2 {
  -webkit-animation: driveRight2 25s ease-in-out 15s infinite;
          animation: driveRight2 25s ease-in-out 15s infinite;
  -webkit-animation-fill-mode: backwards;
          animation-fill-mode: backwards; }

.world--vehicle--drive-right-3 {
  -webkit-animation: driveRight2 25s ease-in-out 25s infinite;
          animation: driveRight2 25s ease-in-out 25s infinite;
  -webkit-animation-fill-mode: backwards;
          animation-fill-mode: backwards; }

@-webkit-keyframes driveLeft1 {
  from {
    -webkit-transform: translateX(80vw);
            transform: translateX(80vw); }
  to {
    -webkit-transform: translateX(-120vw);
            transform: translateX(-120vw); } }

@keyframes driveLeft1 {
  from {
    -webkit-transform: translateX(80vw);
            transform: translateX(80vw); }
  to {
    -webkit-transform: translateX(-120vw);
            transform: translateX(-120vw); } }

@-webkit-keyframes driveLeft2 {
  from {
    -webkit-transform: translateX(80vw);
            transform: translateX(80vw); }
  to {
    -webkit-transform: translateX(-175vw);
            transform: translateX(-175vw); } }

@keyframes driveLeft2 {
  from {
    -webkit-transform: translateX(80vw);
            transform: translateX(80vw); }
  to {
    -webkit-transform: translateX(-175vw);
            transform: translateX(-175vw); } }

@-webkit-keyframes driveRight1 {
  from {
    -webkit-transform: translateX(-80vw);
            transform: translateX(-80vw); }
  to {
    -webkit-transform: translateX(120vw);
            transform: translateX(120vw); } }

@keyframes driveRight1 {
  from {
    -webkit-transform: translateX(-80vw);
            transform: translateX(-80vw); }
  to {
    -webkit-transform: translateX(120vw);
            transform: translateX(120vw); } }

@-webkit-keyframes driveRight2 {
  from {
    -webkit-transform: translateX(-80vw);
            transform: translateX(-80vw); }
  to {
    -webkit-transform: translateX(175vw);
            transform: translateX(175vw); } }

@keyframes driveRight2 {
  from {
    -webkit-transform: translateX(-80vw);
            transform: translateX(-80vw); }
  to {
    -webkit-transform: translateX(175vw);
            transform: translateX(175vw); } }

@-webkit-keyframes driveRight3 {
  from {
    -webkit-transform: translateX(-80vw);
            transform: translateX(-80vw); }
  to {
    -webkit-transform: translateX(200vw);
            transform: translateX(200vw); } }

@keyframes driveRight3 {
  from {
    -webkit-transform: translateX(-80vw);
            transform: translateX(-80vw); }
  to {
    -webkit-transform: translateX(200vw);
            transform: translateX(200vw); } }

@media (orientation: landscape) {
  .world--container {
    grid-template-rows: 35% 50% 15%; }
  .world--container-dark {
    -webkit-max-width: 50%;
    max-width: 50vw; }
  .world--vehicle-container {
    -webkit-max-width: 50%;
    max-width: 50vw; }
  .world--level-marker-text {
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 50%; }
  .world--time-text {
    right: 52%; }
  .world--score-text {
    left: 2%; } }

.building--row-container {
  height: 100%;
  width: 100%;
  display: grid;
  grid-area: rowContainerArea;
  grid-template-areas: "sourceRowArea" "userRowArea";
  grid-template-rows: 50% 50%; }

.building--row {
  height: 100%;
  width: 100%;
  display: grid;
  align-self: center;
  align-items: center;
  justify-items: center;
  grid-template-rows: 100%; }

.building--row__source {
  grid-area: sourceRowArea;
  grid-template-areas: ". buildingLotArea1 buildingLotArea2 buildingLotArea3 .";
  grid-template-columns: 15% 25% 25% 25% 10%;
  z-index: 1; }

.building--row__user {
  grid-area: userRowArea;
  grid-template-areas: ". buildingLotArea1 buildingLotArea2 buildingLotArea3 .";
  grid-template-columns: 10% 25% 25% 25% 15%;
  z-index: 2; }

BldgPart {
  align-items: end;
  display: grid;
  width: 100%;
  height: 100%;
  background-size: contain;
  background-repeat: no-repeat; }

.lot--1 {
  grid-area: buildingLotArea1; }

.lot--2 {
  grid-area: buildingLotArea2; }

.lot--3 {
  grid-area: buildingLotArea3; }

.placeholder--example-1 {
  background-image: url(../images/source-lot--1.svg); }

.placeholder--example-2 {
  background-image: url(../images/source-lot--2.svg); }

.placeholder--example-3 {
  background-image: url(../images/source-lot--3.svg); }

.placeholder--user-1 {
  background-image: url(../images/user-lot--1.svg); }

.placeholder--user-2 {
  background-image: url(../images/user-lot--2.svg); }

.placeholder--user-3 {
  background-image: url(../images/user-lot--3.svg); }

.ground--vacant {
  background-image: url(../images/blank--zone.svg); }

.ground--grass {
  background-image: url(../images/house--zone.svg); }

.ground--asphalt {
  background-image: url(../images/office--zone.svg); }

.foundation--brick {
  background-image: url(../images/house--base__brick.svg); }

.foundation--concrete {
  background-image: url(../images/house--base__gray.svg); }

.foundation--marble {
  background-image: url(../images/house--base__marble.svg); }

.foundation--wood {
  background-image: url(../images/house--base__wood.svg); }

.stairs--brick {
  background-image: url(../images/house--stairs__brick.svg); }

.stairs--concrete {
  background-image: url(../images/house--stairs__gray.svg); }

.stairs--marble {
  background-image: url(../images/house--stairs__marble.svg); }

.stairs--wood {
  background-image: url(../images/house--stairs__wood.svg); }

.main--house-red {
  background-image: url(../images/house--main__red.svg); }

.main--house-green {
  background-image: url(../images/house--main__green.svg); }

.main--house-blue {
  background-image: url(../images/house--main__blue.svg); }

.main--office-red {
  background-image: url(../images/office--main__red.svg); }

.main--office-green {
  background-image: url(../images/office--main__green.svg); }

.main--office-blue {
  background-image: url(../images/office--main__blue.svg); }

.main--office-glass {
  background-image: url(../images/empty--main.svg); }

.roof--house-red {
  background-image: url(../images/house--roof__red.svg); }

.roof--house-green {
  background-image: url(../images/house--roof__green.svg); }

.roof--house-blue {
  background-image: url(../images/house--roof__blue.svg); }

.roof--office-red {
  background-image: url(../images/office--roof__red.svg); }

.roof--office-green {
  background-image: url(../images/office--roof__green.svg); }

.roof--office-blue {
  background-image: url(../images/office--roof__blue.svg); }

.roof--office-glass {
  background-image: url(../images/empty--roof.svg); }

.decor--flowers {
  background-image: url(../images/accessory--flowers.svg); }

.decor--graffiti {
  background-image: url(../images/accessory--graffiti.svg); }

.decor--cactii {
  background-image: url(../images/accessory--cactii.svg); }

.decor--logo {
  background-image: url(../images/accessory--logo.svg); }

.decor--damage {
  background-image: url(../images/accessory--hole.svg); }

.decor--trees {
  background-image: url(../images/accessory--trees.svg); }

.decor--vines {
  background-image: url(../images/accessory--vines.svg); }

.menu--container {
  background-image: linear-gradient(-45deg, #3a4352, #393939);
  display: grid;
  align-items: center;
  justify-self: center;
  justify-items: center;
  height: 100%;
  width: 100%;
  grid-area: menuArea;
  grid-template-areas: "." "blueprintArea" "bossMessageArea" ".";
  grid-template-rows: 1% 45% 50% 4%;
  box-shadow: -0.2em -0.4em 0.6em #00000060;
  z-index: 4; }

.menu--boss-message-container {
  align-self: center;
  justify-self: center;
  justify-items: center;
  width: 80%;
  height: 100%;
  display: grid;
  grid-template-areas: "bossMessageBossArea bossMessageTextArea";
  grid-area: bossMessageArea;
  grid-template-columns: 25% 75%;
  grid-template-rows: 100%;
  background-color: #dfdfdf;
  background-image: linear-gradient(transparent, transparent, #00000040);
  box-sizing: border-box;
  box-shadow: 0 0.2em 0.8em #00000060; }

.menu--blueprint-container {
  width: 100%;
  max-height: 100%;
  align-self: center;
  justify-self: center;
  display: grid;
  grid-area: blueprintArea; }

textarea.menu--blueprint__textarea {
  justify-self: center;
  align-self: center;
  min-width: 80%;
  max-width: 95%;
  height: 100%;
  padding: 1em;
  font-family: monospace;
  background-color: #31406b;
  border: none;
  color: white;
  font-size: calc(0.95vw + 0.95vh);
  box-shadow: 0 0.2em 0.8em #00000060; }

.menu--boss-message__text-container {
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  padding: 0.5em;
  text-align: left;
  align-self: center;
  align-items: start;
  max-height: 100%;
  width: 100%;
  display: grid;
  grid-area: bossMessageTextArea; }

.menu--boss-message__text {
  color: #141a2b;
  font-size: calc(0.80vh + 0.80vw);
  line-height: 2; }

.menu--boss-message__boss-container {
  justify-items: center;
  align-self: center;
  height: 100%;
  width: 100%;
  display: grid;
  grid-area: bossMessageBossArea; }

.menu--boss-message__boss {
  padding: 0.5em;
  width: 100%;
  height: 100%; }

.menu--high-score-container {
  width: 60%;
  height: 75%;
  position: absolute;
  top: 12%;
  left: 20%;
  background-color: brown;
  z-index: 20;
  box-shadow: 0 0.3em 0.5em #00000080;
  display: grid;
  align-items: center;
  justify-items: center; }

.menu--high-score-button {
  color: white;
  font-size: calc(1vh + 1vw);
  height: calc(4vh + 4vw);
  width: calc(4vh + 4vw);
  border-radius: 50%;
  border: solid white;
  background-color: none; }

@media (orientation: landscape) {
  textarea.menu--blueprint__textarea {
    width: 90%;
    height: 150%;
    font-size: calc(0.65vw + 0.65vh); }
  .menu--boss-message-container {
    width: 90%;
    height: 90%; } }

.effect--glow {
  -webkit-animation: glow 2s linear infinite;
          animation: glow 2s linear infinite; }

.effect--pump {
  -webkit-animation: pump 1s alternate infinite;
          animation: pump 1s alternate infinite; }

@-webkit-keyframes glow {
  0% {
    opacity: 1;
    -webkit-filter: brightness(1);
            filter: brightness(1); }
  50% {
    opacity: 0.15;
    -webkit-filter: brightness(0.75);
            filter: brightness(0.75); }
  100% {
    opacity: 1;
    -webkit-filter: brightness(1);
            filter: brightness(1); } }

@keyframes glow {
  0% {
    opacity: 1;
    -webkit-filter: brightness(1);
            filter: brightness(1); }
  50% {
    opacity: 0.15;
    -webkit-filter: brightness(0.75);
            filter: brightness(0.75); }
  100% {
    opacity: 1;
    -webkit-filter: brightness(1);
            filter: brightness(1); } }

@-webkit-keyframes pump {
  from {
    opacity: 0.6;
    -webkit-transform: scale(1);
            transform: scale(1); }
  to {
    opacity: 1;
    -webkit-transform: scale(1.02);
            transform: scale(1.02); } }

@keyframes pump {
  from {
    opacity: 0.6;
    -webkit-transform: scale(1);
            transform: scale(1); }
  to {
    opacity: 1;
    -webkit-transform: scale(1.02);
            transform: scale(1.02); } }

@-webkit-keyframes pump-big {
  from {
    -webkit-transform: scale(1);
            transform: scale(1); }
  to {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
    box-shadow: 0 0.2em 0.6em #00000080; } }

@keyframes pump-big {
  from {
    -webkit-transform: scale(1);
            transform: scale(1); }
  to {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
    box-shadow: 0 0.2em 0.6em #00000080; } }

.app--page {
  height: 100%;
  width: 100%;
  background-color: #848484;
  display: grid;
  justify-items: center;
  align-items: center;
  align-self: center;
  grid-template-areas: "headerArea" "contentArea";
  grid-template-rows: 10% 90%; }

.app--header__logo {
  display: grid;
  max-height: 100%;
  width: auto;
  grid-area: headerLogoArea; }

.app--header__nav {
  max-height: 100%;
  display: grid;
  margin-right: 0.5em;
  justify-self: end;
  grid-area: headerNavArea; }

.app--header__nav button {
  border-radius: 0;
  -webkit-animation: none;
          animation: none;
  border: none;
  font-size: calc(0.65vh + 0.65vw);
  background-color: #848484;
  box-shadow: 0 0.1em 0.3em #00000040;
  text-shadow: 0 0.1em 0.2em #00000080;
  margin: 0.25em;
  padding: 0.5em;
  color: white;
  text-decoration: none; }

.app--header__nav button:hover {
  background-color: #919191;
  box-shadow: 0 0.2em 0.5em #00000040;
  color: #92c5ff; }

.app--header__nav button:active {
  box-shadow: 0 0 0.1em #00000070;
  background-color: #535353;
  color: #ff6b6b; }

.app--header__high-score {
  font-size: calc(0.85vw + 0.85vh);
  max-height: 100%;
  justify-self: start;
  margin-left: 2em;
  text-shadow: 0 0 0.5em #00000080;
  line-height: 1.25;
  display: grid;
  grid-area: highScoreArea; }

.app--header {
  display: grid;
  justify-items: center;
  align-items: center;
  width: 100%;
  height: 100%;
  grid-area: headerArea;
  background-image: linear-gradient(-45deg, #848484, #535353);
  box-shadow: 0 0.2em 0.4em #00000040;
  z-index: 5;
  grid-template-areas: "highScoreArea headerLogoArea headerNavArea";
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 100%; }

.app--content {
  background-color: #848484;
  align-items: center;
  justify-items: center;
  width: 100%;
  height: 100%;
  grid-area: contentArea;
  display: grid;
  grid-template-areas: "buildingArea" "menuArea";
  grid-template-rows: 45% 55%; }

@media only screen and (orientation: landscape) {
  .app--content {
    grid-template-areas: "buildingArea menuArea";
    grid-template-columns: 50% 50%;
    grid-template-rows: 100%; }
  .app--header__nav {
    display: flex; } }

.menu--container{
    background-image: linear-gradient(-45deg, #3a4352, #393939);
    display: grid;
    align-items: center;
    justify-self: center;
    justify-items: center;
    height: 100%;
    width: 100%;
    grid-area: menuArea;
    grid-template-areas:
    "."
    "blueprintArea"
    "bossMessageArea"
    ".";
    grid-template-rows: 1% 45% 50% 4%;
    box-shadow: -0.2em -0.4em 0.6em #00000060;
    z-index: 4;
}

.menu--boss-message-container{
    align-self: center;
    justify-self: center;
    justify-items: center;
    width: 80%;
    height: 100%;
    display: grid;
    grid-template-areas:
    "bossMessageBossArea bossMessageTextArea";
    grid-area: bossMessageArea;
    grid-template-columns: 25% 75%;
    grid-template-rows: 100%;
    background-color: #dfdfdf;
    background-image: linear-gradient(transparent, transparent, #00000040);
    box-sizing: border-box;
    box-shadow: 0 0.2em 0.8em #00000060;
}
  
.menu--blueprint-container{
    width:100%;
    max-height: 100%;
    align-self: center;
    justify-self: center;
    display: grid;
    grid-area: blueprintArea;
}
  
textarea.menu--blueprint__textarea{
    justify-self: center;
    align-self: center;
    min-width: 80%;
    max-width: 95%;
    height: 100%;
    padding: 1em;
    font-family: monospace;
    background-color: #31406b;
    border: none;
    color: white;
    font-size: calc(0.95vw + 0.95vh);
    box-shadow: 0 0.2em 0.8em #00000060;
}

.menu--boss-message__text-container{
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    padding: 0.5em;
    text-align: left;
    align-self: center;
    align-items: start;
    max-height: 100%;
    width: 100%;
    display: grid;
    grid-area: bossMessageTextArea;
}

.menu--boss-message__text{
    color: #141a2b;
    font-size: calc(0.80vh + 0.80vw);
    line-height: 2;
}
  
.menu--boss-message__boss-container{
    justify-items: center;
    align-self: center;
    height: 100%;
    width: 100%;
    display: grid;
    grid-area: bossMessageBossArea;
}
  
.menu--boss-message__boss{
  padding: 0.5em;
  width: 100%;
  height: 100%;
}

.menu--high-score-container{
  width: 60%;
  height: 75%;
  position: absolute;
  top: 12%;
  left: 20%;
  background-color: brown;
  z-index: 20;
  box-shadow: 0 0.3em 0.5em #00000080;
  display: grid;
  align-items: center;
  justify-items: center;
}

.menu--high-score-button{
  color: white;
  font-size: calc(1vh + 1vw);
  height: calc(4vh + 4vw);
  width: calc(4vh + 4vw);
  border-radius: 50%;
  border: solid white;
  background-color: none;
}


@media (orientation: landscape) {
  textarea.menu--blueprint__textarea{
    width: 90%;
    height: 150%;
    font-size: calc(0.65vw + 0.65vh);
  }

  .menu--boss-message-container{
    width: 90%;
    height: 90%;
}


}

//Page
$bg-color: #848484;
$main-font: 'Josefin Sans';


//World
$skyline: url(../images/skyline.svg);
$source-lot--1: url(../images/source-lot--1.svg);
$source-lot--2: url(../images/source-lot--2.svg);
$source-lot--3: url(../images/source-lot--3.svg);
$user-lot--1: url(../images/user-lot--1.svg);
$user-lot--2: url(../images/user-lot--2.svg);
$user-lot--3: url(../images/user-lot--3.svg);
$car--white: url(../images/car--white.svg);
$truck--brown: url(../images/truck--brown.svg);

//Zone
$blank--zone: url(../images/blank--zone.svg);
$house--zone: url(../images/house--zone.svg);
$office--zone: url(../images/office--zone.svg);

//Base
$base__marble: url(../images/house--base__marble.svg);
$base__brick: url(../images/house--base__brick.svg);
$base__gray: url(../images/house--base__gray.svg);
$base__wood: url(../images/house--base__wood.svg);

//Stairs
$stairs__brick: url(../images/house--stairs__brick.svg);
$stairs__gray: url(../images/house--stairs__gray.svg);
$stairs__marble: url(../images/house--stairs__marble.svg);
$stairs__wood: url(../images/house--stairs__wood.svg);

//Main
$empty--main: url(../images/empty--main.svg);
$house--main__red: url(../images/house--main__red.svg);
$house--main__green: url(../images/house--main__green.svg);
$house--main__blue: url(../images/house--main__blue.svg);
$office--main__red: url(../images/office--main__red.svg);
$office--main__green: url(../images/office--main__green.svg);
$office--main__blue: url(../images/office--main__blue.svg);

//Roof
$empty--roof: url(../images/empty--roof.svg);
$house--roof__red: url(../images/house--roof__red.svg);
$house--roof__green: url(../images/house--roof__green.svg);
$house--roof__blue: url(../images/house--roof__blue.svg);
$office--roof__red: url(../images/office--roof__red.svg);
$office--roof__green: url(../images/office--roof__green.svg);
$office--roof__blue: url(../images/office--roof__blue.svg);

//Accessories
$accessory--flowers: url(../images/accessory--flowers.svg);
$accessory--graffiti: url(../images/accessory--graffiti.svg);
$accessory--cactii: url(../images/accessory--cactii.svg);
$accessory--logo: url(../images/accessory--logo.svg);
$accessory--hole: url(../images/accessory--hole.svg);
$accessory--trees: url(../images/accessory--trees.svg);
$accessory--vines: url(../images/accessory--vines.svg);
